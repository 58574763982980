<template>
  <b-modal
    ref="modalUpsertTemplate"
    :visible="editCourseSectionActive"
    @hidden="closeModal"
    size="lg"
    :busy="busy"
    :title="`Edit Course Section`"
    ok-title="Save Changes"
    centered
    body-class="pt-0"
    @ok="saveChanges"
  >
    <b-row>
      <b-col cols="12" md="12" lg="12">
        <b-form-group label="Section Alias" label-for="course-section-nickname">
          <b-form-input id="course-section-nickname" placeholder="" v-model="courseSectionEditData.nickname" />
        </b-form-group>
      </b-col>
      <b-col cols="12" md="12" lg="6">
        <b-form-group label="Zoom Meeting ID" label-for="course-section-zoom-meeting-id">
          <b-form-input id="course-section-zoom-meeting-id" placeholder="" v-model="courseSectionEditData.zoomMeetingId" />
        </b-form-group>
      </b-col>
      <b-col cols="12" md="12" lg="6">
        <b-form-group label="Discord Server ID" label-for="course-section-discord-server-id">
          <b-form-input id="course-section-discord-server-id" placeholder="" v-model="courseSectionEditData.discordServerId" />
        </b-form-group>
      </b-col>
      <b-col cols="12" md="12" lg="12">
        <b-form-group label="Discord Server Invite URL" label-for="course-section-discord-server-url">
          <b-form-input id="course-section-discord-server-url" placeholder="" v-model="courseSectionEditData.discordInviteUrl" />
        </b-form-group>
      </b-col>
      <b-col cols="12" md="4" lg="6">
        <b-form-group label="Visibility" label-for="course-section-visibility">
          <v-select
            v-model="courseSectionEditData.visibility"
            :options="visibilityOptions"
            :reduce="(val) => val.value"
            :clearable="false"
            required
            transition="smooth"
            input-id="course-section-visibility"
          />
        </b-form-group>
      </b-col>
      <b-col cols="12" md="12" lg="6">
        <b-form-group label="MyIP Integration/Automation" label-for="course-section-myip-integration">
          <b-form-checkbox
            id="course-section-myip-integration"
            :checked="courseSectionEditData.metadata && courseSectionEditData.metadata.myIp.enabled"
            @change="toggleMyIPEnabled"
            switch
            class="custom-control-primary"
            style="margin-top: 0.8rem"
          >
            {{ courseSectionEditData.metadata && courseSectionEditData.metadata.myIp.enabled ? 'Enabled' : 'Disabled' }}
          </b-form-checkbox>
        </b-form-group>
      </b-col>
      <!-- <b-col cols="12" md="4" lg="4">
        <b-form-group label="Status" label-for="course-section-status">
          <v-select
            v-model="courseSectionEditData.status"
            :options="statusOptions"
            :reduce="(val) => val.value"
            :clearable="false"
            required
            transition="smooth"
            input-id="course-section-status"
          />
        </b-form-group>
      </b-col> -->
    </b-row>
  </b-modal>
</template>

<script>
import {
  BCol,
  BFormGroup,
  BButton,
  BFormInput,
  BFormTextarea,
  BFormSelect,
  BFormSelectOption,
  BFormCheckbox,
  BModal,
  BRow,
  VBModal,
} from 'bootstrap-vue';

import 'quill/dist/quill.snow.css';
import { quillEditor } from 'vue-quill-editor';
import store from '@/store';
import { ref } from '@vue/composition-api/dist/vue-composition-api';
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue';
import useCourseSections from '../useCourseSections';
import vSelect from 'vue-select';

export default {
  name: 'CourseSectionEditModal',
  components: {
    BRow,
    BCol,
    BFormGroup,
    BButton,
    BFormInput,
    BFormTextarea,
    BFormSelect,
    BFormSelectOption,
    BFormCheckbox,
    BModal,
    quillEditor,
    vSelect,
  },
  directives: { 'b-modal': VBModal },
  props: {
    editCourseSectionActive: {
      type: Boolean,
      required: true,
      default: false,
    },
    courseSectionData: {
      type: Object,
      default: undefined,
    },
  },
  watch: {
    '$props.editCourseSectionActive'(val) {
      this.initModal();
    },
    '$props.courseSectionData'(val) {
      this.initModal();
    },
  },
  mounted() {
    this.initModal();
  },
  methods: {
    initModal() {
      if (this.$props.courseSectionData) {
        console.log(this.$props.courseSectionData);

        const { nickname, visibility, id, zoomMeetingId, discordServerId, discordInviteUrl, metadata } = this.$props.courseSectionData;
        this.courseSectionEditData = { nickname, visibility, id, zoomMeetingId, discordServerId, discordInviteUrl, metadata };
      }
    },
    closeModal() {
      this.busy = false;
      this.$emit('update:edit-course-section-active', false);
    },
    toggleMyIPEnabled() {
      if (!this.courseSectionEditData.metadata.myIp) {
        this.courseSectionEditData.metadata.myIp = { enabled: true };
      } else {
        this.courseSectionEditData.metadata.myIp.enabled = !this.courseSectionEditData.metadata.myIp.enabled;
      }
    },
    saveChanges(event) {
      this.busy = true;

      event.preventDefault();

      const pl = { ...this.courseSectionEditData };

      console.log(pl);

      store
        .dispatch('rosterStoreModule/updateCourseSection', { courseSectionId: pl.id, courseSectionData: pl })
        .then((response) => {
          // this.$emit('update:course-data', response.data);

          this.busy = false;
          this.$emit('refetch-data');
          this.closeModal();

          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Course Cohort Edited!',
              icon: 'EditIcon',
              variant: 'success',
            },
          });
        })
        .catch((error) => {
          this.busy = false;
          this.$emit('refetch-data');
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Failed to edit course cohort',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
              text: error.response ? error.response.data.message : error.message,
            },
          });
        });
    },
  },
  setup(props) {
    const courseSectionEditData = ref({});
    const busy = ref(false);

    const { statusOptions, visibilityOptions } = useCourseSections();

    return { statusOptions, visibilityOptions, courseSectionEditData, busy };
  },
};
</script>

<style lang="scss">
@import '@core/scss/vue/libs/quill.scss';
</style>
